@font-face {
  font-family: OpenSans;
  src: url("../OpenSans-Regular.c9c88c28.woff2") format("woff2"), url("../OpenSans-Regular.ad84207d.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url("../OpenSansSemiCondensed-SemiBold.304a9557.woff2") format("woff2"), url("../OpenSansSemiCondensed-SemiBold.d790828c.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: OpenSans;
  src: url("../OpenSans-Light.b35e9928.woff2") format("woff2"), url("../OpenSans-Light.f5e732a0.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

.logo {
  aspect-ratio: 1;
  background: url("../logo.4ecfd9e9.svg") 50% / contain no-repeat;
  width: 40%;
  margin: 1rem auto;
}

* {
  box-sizing: border-box;
  font-family: OpenSans, Calibri, sans-serif;
}

a {
  color: #6a6;
  text-decoration: underline .5ex #6a66;
}

body, html {
  margin: 0;
  padding: 0;
}

html {
  color: #555;
  background: url("../bg.77ad4886.svg") fixed, linear-gradient(to top right, #fff 20%, #eee 100%) fixed;
  font-size: clamp(13px, 1.7vw, 20px);
}

h1, h2 {
  color: #6f6f6f;
  font-weight: 100;
  text-decoration: underline .5rem #fff;
}

h1 {
  background: #d5d5d5;
  margin: 0;
  padding: 2rem;
  font-size: 3rem;
}

h2 {
  font-size: 2.5rem;
}

body {
  grid-template-areas: "menu header"
                       "menu content"
                       "menu footer";
  display: grid;
  overflow-x: hidden;
}

@media (width >= 640px) {
  body {
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 30vw 70vw;
  }
}

@media (width <= 639px) {
  body {
    grid-template-rows: auto 1fr auto;
    grid-template-columns: 0 100vw;
  }
}

body > header {
  grid-area: header;
}

body > nav {
  grid-area: menu;
  font-weight: 100;
}

body > nav > ul {
  z-index: 10;
  backdrop-filter: blur(7px);
  background: linear-gradient(45deg, #ccc, #0000);
  min-height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  transition: all .1s;
  position: relative;
  box-shadow: 1rem 0 2rem #0003;
}

body > nav > ul > li a {
  padding: 1rem;
  font-size: 1.7rem;
  display: block;
}

@media (width >= 640px) {
  body > nav > ul > li a {
    text-align: right;
  }
}

@media (width <= 639px) {
  body > nav > ul > li a {
    text-align: center;
  }
}

body > nav > ul > li a:hover {
  background-color: #d5d5d5;
}

body > nav > ul > .my-name {
  text-align: center;
  font-size: 1.7rem;
}

@media (width >= 640px) {
  body > nav > ul > .my-name {
    display: none;
  }

  body > nav > ul {
    width: 30vw;
  }
}

@media (width <= 639px) {
  body > nav > ul {
    width: 90vw;
    height: 100vh;
    position: fixed;
    left: -90vw;
    overflow: auto;
  }
}

@media (width >= 640px) {
  body > nav .menu-toggler {
    display: none;
  }
}

@media (width <= 639px) {
  body > nav .menu-toggler {
    z-index: 20;
    position: fixed;
    top: 3rem;
    right: 3rem;
  }

  body > nav .menu-toggler:after {
    content: " ";
    background-image: url("../menu-icon.e2966b95.svg");
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    width: 5rem;
    height: 5rem;
    display: block;
    position: relative;
    top: -2rem;
    left: -2rem;
  }
}

body > nav .menu-toggler:checked + ul {
  left: 0;
}

body > section {
  grid-area: content;
  padding: 1rem;
}

body > footer {
  text-align: center;
  color: #888;
  grid-area: footer;
  padding: 1rem;
}
/*# sourceMappingURL=main.css.map */
