$default_color: #555;
$default_bg_color: #eee;
$accent_color: #6a6;

@import 'fonts';

@mixin mobile() {
	@media (max-width: 639px) {
		@content;
	}	
}	
@mixin desktop() {
	@media (min-width: 640px) {
		@content;
	}	
}

.logo {
	background: url(../images/logo.svg) no-repeat 50% 50%;
	background-size: contain;
	aspect-ratio: 1;
	width: 40%;
	margin: 1rem auto;
}

* {
	font-family: OpenSans, Calibri, sans-serif;
	box-sizing: border-box;
}
a {
	color: $accent_color;
	text-decoration: rgba($accent_color, 0.4) underline 0.5ex;
}
body, html {
	padding: 0;
	margin: 0;
}
html {
	font-size: 15px;
	font-size: clamp(13px, 1.7vw, 20px);
	color: $default_color;
	background: url('../images/bg.svg') fixed, linear-gradient(to right top, white 20%, $default_bg_color 100%) fixed;
}
h1, h2 {
	font-weight: 100;
	color: lighten($color: $default_color, $amount: 10%);
	text-decoration: underline white 0.5rem;
} 
h1 {
	font-size: 3rem;
	margin: 0;
	padding: 2rem;
	background: darken($default_bg_color, 10%);
}
h2 {
	font-size: 2.5rem;
}
body {
	display: grid;
	@include desktop() {
		grid-template-rows: auto 1fr auto;
		grid-template-columns: 30vw 70vw;
	}
	@include mobile() {
		grid-template-rows: auto 1fr auto;
		grid-template-columns: 0 100vw;
	}
	// > * {
	// 	border: 1px solid red;
	// 	> * {
	// 		border: 1px solid green;
	// 	}
	// }
	overflow-x: hidden;
	grid-template-areas: 
		"menu header"
		"menu content"
		"menu footer";

	> header { grid-area: header; }
	> nav { 
		grid-area: menu;
		font-weight: 100;
		> ul {
			list-style: none;
			z-index: 10;
			position: relative;
			box-shadow: 1rem 0 2rem #0003;
			padding: 0;
			margin: 0;
			> li {
				a {
					display: block;
					padding: 1rem;
					font-size: 1.7rem;
					@include desktop() {
						text-align: right;
					}
					@include mobile() {
						text-align: center;
					}
					&:hover {
						background-color: darken($color: $default_bg_color, $amount: 10);
					}
				}
			}
			> .my-name {
				font-size: 1.7rem;
				text-align: center;
				@include desktop() {
					display: none;
				}
			}
			@include desktop() {
				width: 30vw;
			}
			@include mobile() {
				width: 90vw;
				position: fixed;
				left: -90vw;
				overflow: auto;
				height: 100vh;
			}
			min-height: 100%;
			background: linear-gradient(45deg, #ccc, transparent);
			backdrop-filter: blur(7px);
			transition: all 100ms;
		}
		.menu-toggler {
			@include desktop() {
				display: none;
			}
			@include mobile() {
				position: fixed;
				z-index: 20;
				right: 3rem;
				top: 3rem;
				
				&::after {
					position: relative;
					left: -2rem;
					top: -2rem;
					width:  5rem;
					height: 5rem;	
					background-image: url(../images/menu-icon.svg);
					background-repeat: no-repeat;
					background-position: 50% 50%;
					background-size: contain;
					content: ' ';
					display: block;
				}
			}
		}
		.menu-toggler:checked+ul {
			left: 0;
		}
	}
	> section { 
		grid-area: content;
		padding: 1rem;
	}
	> footer {
		grid-area: footer;
		text-align: center;
		color: lighten($color: $default_color, $amount: 20%);
		padding: 1rem;
	}
}